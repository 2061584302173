<template>
  <section>
    <button-back
      v-if="$route.query.event"
      @click="goBack"
    >
      Back to League
    </button-back>

    <validation-form
      ref="validationForm"
      :submit-buttons="{
        loading: form.isLoading,
        cancel: $route.query.event !== null,
        saveAndNew: isNewTeam,
      }"
      @cancel="goBack"
      @validated="isNewTeam ? createTeam() : updateTeam()"
    >
      <b-card>
        <!-- Info -->
        <form-team-info :team="form" />

        <!-- Social Media -->
        <form-social-media
          :social-media="form.social_media || undefined"
          @update:social-media="form.social_media = $event"
        />
      </b-card>
    </validation-form>

    <!-- Delete -->
    <card-delete
      v-if="!isNewTeam"
      item-name="team"
      alert-body="Once you delete this team, past records from all seasons will be permanently deleted. This action cannot be undone."
      @delete="$store.commit('app/deleteModal/UPDATE_DELETE_MODAL' ,{
        title: 'Delete Team',
        itemName: form.name,
        endpoint: `/teams/${form.id}`,
        afterDelete: goBack
      })"
    />
  </section>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import ButtonBack from '@/components/buttons/ButtonBack.vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import FormTeamInfo from '@/components/forms/FormTeamInfo.vue'
import FormSocialMedia from '@/components/forms/FormSocialMedia.vue'
import CardDelete from '@/components/cards/CardDelete.vue'

import Form from '@/forms/Form'
import axios from '@/libs/axios'
import useFlash from '@/composables/useFlash'

const { flashError } = useFlash()

export default {
  name: 'EventTeam',
  components: {
    BCard,
    ButtonBack,
    ValidationForm,
    FormTeamInfo,
    FormSocialMedia,
    CardDelete,
  },
  data() {
    return {
      form: new Form({
        name: '',
        abbreviation: '',
        logo: undefined,
        logo_url: '',
        level: null,
        age: null,
        grade: null,
        gender: null,
        sport: null,
        social_media: null,
        address: null,
      }, true),
    }
  },
  computed: {
    isNewTeam() {
      return !this.$route.params.team
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.team) {
      next()
      return
    }

    axios.get(`/teams/${to.params.team}`)
      .then(response => {
        const { data } = response.data

        next(vm => {
          Object.assign(vm.form, data)
        })
      })
      .catch(error => {
        flashError(error)
      })
  },
  mounted() {
    this.form.validationObserver = this.$refs.validationForm.$refs.observer
  },
  methods: {
    goBack() {
      this.$router.back()
      // this.$router.push({
      //   name: 'event.teams',
      //   params: { event: this.$route.query.event },
      // })
    },
    createTeam(createNew) {
      this.form.post(`/teams?organization_id=${this.event.organization_id}`, true)
        .then(response => {
          if (createNew) {
            document.getElementById('name').focus()
            return
          }

          const { data } = response

          Object.assign(this.form, data)
          this.$router.push({ name: 'team', params: { team: data.id } })
        })
    },
    updateTeam() {
      this.form.post(`/teams/${this.$route.params.team}`, false, true)
        .then(() => {
          this.goBack()
        })
    },
  },
}
</script>
