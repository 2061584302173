<template>
  <b-card no-body>
    <b-card-title>
      Social Media
    </b-card-title>

    <b-row>
      <b-col
        v-for="(value, brand) in localSocialMedia"
        :key="brand"
        sm="6"
      >
        <b-form-group
          :label="getBrandCasing(brand)"
          :label-for="brand"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon :icon="getIcon(brand)" />
            </b-input-group-prepend>
            <b-form-input
              :id="brand"
              v-model="localSocialMedia[brand]"
              :placeholder="getBrandCasing(brand)"
              aria-autocomplete="none"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BRow, BCol, BFormGroup, BInputGroup, BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  props: {
    socialMedia: {
      type: Object,
      default() {
        return {
          facebook: null,
          twitter: null,
          youtube: null,
          instagram: null,
          website: null,
        }
      },
    },
  },
  data() {
    return {
      localSocialMedia: '',
    }
  },
  watch: {
    socialMedia(value) {
      this.localSocialMedia = value
    },
    localSocialMedia: {
      handler(value) {
        this.$emit('update:social-media', value)
      },
      deep: true,
    },
  },
  mounted() {
    this.localSocialMedia = this.socialMedia
  },
  methods: {
    getIcon(brand) {
      const prefix = 'fa-brands'

      switch (brand) {
        case 'facebook':
          return `${prefix} fa-square-facebook`
        case 'website':
          return 'fa-light fa-link'
        default:
          return `${prefix} fa-${brand}`
      }
    },
    getBrandCasing(brand) {
      switch (brand) {
        case 'youtube':
          return 'YouTube'
        default:
          return brand.charAt(0).toUpperCase() + brand.slice(1)
      }
    },
  },
}
</script>

<style scoped>

</style>
