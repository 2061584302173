<template>
  <div>
    <b-button
      variant="outline-primary"
      size="sm"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <font-awesome-icon
        icon="fa-light fa-arrow-left"
        size="lg"
        fixed-width
      />
      <slot>Back</slot>
    </b-button>

    <!-- Page Title -->
    <portal-target
      v-if="$route.meta.breadcrumbPortal"
      name="breadcrumb"
      class="mt-2"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
}
</script>

<style scoped>

</style>
